<template>
  <div class="bg flex justify-center items-center">
    No details found
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.bg {
  background: #fff;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  height: 4rem;
}
.comparison-wrapper {
  .bg {
    background: aliceblue;
  }
}
</style>
